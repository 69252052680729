import { useEffect, useState, memo, useRef } from 'react'
import Link from 'next/link'
import Image from 'next/legacy/image'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
// Components
import Loader from '../newLoader/Loader'
import Button from '../newButton/Button'
import styles from './Explore.module.scss'
// Utils
import { getBreedImage, pickBreeds } from './lib/utils'
import query from '@/utils/query'
import { BREED_LIST_EXPLORER } from '@/graphql/index'
import useMediaQuery from '@/utils/publicApi'

const BreedCard = memo(({ breed }) => (
  <div className={cn(styles.breed, styles.withDogs)}>
    <Link href={`/search?breed=${breed?.slug}`} prefetch={false} alt={breed?.name}>

      <Image
        src={
          getBreedImage(breed)
            ? `${process.env.NEXT_PUBLIC_MEDIA_BUCKET}${getBreedImage(breed)}`
            : '/placeholder-breed.svg'
        }
        role='img'
        aria-label={`${breed?.name} sitting and posing`}
        alt={`${breed?.name} sitting and posing`}
        width={165}
        height={165}
        loading='lazy'
        layout='responsive'
      />
      <p>{breed?.name} </p>

    </Link>
  </div>
))

export default function Explore ({ breeds: _breeds, title }) {
  // Breeds fetched
  // const limit = 12
  const isMobile = useMediaQuery('(max-width:600px)')
  const [limit, setLimit] = useState(isMobile ? 12 : 12)

  const [offset, setOffset] = useState(0)
  const [fetchedBreeds, setFetchedBreeds] = useState()
  const [hasLoaded, setHasLoaded] = useState(false)
  // Breeds array
  const [breeds, setBreeds] = useState([])
  // Breeds renderer
  const [breedsShown, setBreedsShown] = useState(12)

  useEffect(() => {
    setBreeds(_breeds.sort((a, b) => b.dogsAvailable - a.dogsAvailable))
  }, [])

  async function breedsFetch (isFetchMobile) {
    (await isFetchMobile) && setOffset((curr) => curr + 1)
    const { breedsList } = await query({
      query: BREED_LIST_EXPLORER,
      variables: { limit, offset }
    })
    setBreeds([...breeds, ...breedsList])
    setFetchedBreeds(breedsList)
    setHasLoaded(false)
  }

  useEffect(() => {
    if (breeds?.length) breedsFetch()
  }, [offset])

  // desktop
  // const breeds = useMemo(() => filteredBreeds.slice(0, breedsShowNumber), [filteredBreeds, breedsShowNumber])

  // mobile
  //   const pairBreeds = useMemo(() => hasSearch ? pickBreeds(filteredBreeds) : pickBreeds(smallBreedData), [hasSearch, filteredBreeds, smallBreedData])

  const handleViewMore = () => {
    setBreedsShown((curr) => curr + limit)
    setHasLoaded(true)
    // Trigger fetch only if there are more breeds
    if (fetchedBreeds?.length || fetchedBreeds === undefined) { setOffset((curr) => curr + 1) }
  }

  const handleViewLess = () => {
    breedsShown > 12 && setBreedsShown((curr) => curr - limit)
  }

  /* Swiper Handlers   */

  const ref = useRef(null)
  const [isBeginning, setIsBeginning] = useState(true)
  const [isEnd, setIsEnd] = useState(false)

  const goNext = async () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      await ref.current.swiper.slideNext()
      setIsBeginning(ref.current?.swiper?.isBeginning)
      setIsEnd(ref.current?.swiper?.isEnd)
    }
  }

  const goPrev = async () => {
    if (ref.current !== null && ref.current.swiper !== null) {
      await ref.current.swiper.slidePrev()
      setIsBeginning(ref.current?.swiper?.isBeginning)
      setIsEnd(ref.current?.swiper?.isEnd)
    }
  }

  return (
    <div className={styles.explore} id='explore'>
      <div className={styles.header}>
        <h2>{title}</h2>
        <a href={`${process.env.NEXT_PUBLIC_ROOT_URL}/puppies-for-sale#breed`}>
          View All
        </a>
      </div>

      <>
        {/* desktop */}
        <div
          className={cn(
            styles.breeds,
            styles.desktop
            // styles.searchDesktop
          )}
        >
          {breeds?.slice(0, breedsShown).map((b, i) => (
            <BreedCard key={i} breed={b} />
          ))}
        </div>
        {hasLoaded && (
          <div className={styles.loader}>
            <Loader theme={['tall']} />
          </div>
        )}
        {/* mobile */}
        <div className={cn(styles.breeds, styles.mobile, styles.searchMobile)}>
          <button
            className={cn({ [styles.swiperButton]: true, [styles.swiperPrev]: !isBeginning, [styles.disabledPrev]: isBeginning })}
            onClick={goPrev}
            role='img' aria-label='Button Prev'
          />
          <button
            className={cn({ [styles.swiperButton]: true, [styles.swiperNext]: !isEnd, [styles.disabledNext]: isEnd })}
            onClick={() => {
              goNext()
              setLimit(limit + 1)
            }}
            role='img' aria-label='Button Next'
          />
          <Swiper
            speed={1000}
            spaceBetween={20}
            initialSlide={0}
            grabCursor
            slidesPerView={3}
            onSlideChange={() => {
              if (fetchedBreeds?.length || fetchedBreeds === undefined) { breedsFetch() }
            }}
            ref={ref}
          >
            {pickBreeds(breeds).map((pair, i) => (
              <SwiperSlide key={i}>
                <div className={styles.pair}>
                  <BreedCard breed={pair[0]} />
                  <BreedCard breed={pair[1]} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </>

      <div className={styles.buttonContainer}>
        {breedsShown > 12 && (
          <Button
            title='View less'
            theme={['black', 'medium']}
            rightImage='/search/arrow-top-black.svg'
            width='10px'
            height='10px'
            onClick={handleViewLess}
            alt='View more'
            altImg='Black up arrow'
          />
        )}
        {breedsShown >= 12 && (
          <Button
            title='View more'
            theme={['black', 'medium']}
            rightImage='/search/arrow-down-black.svg'
            width='10px'
            height='10px'
            onClick={handleViewMore}
            alt='View more'
            altImg='Black down arrow'
          />
        )}
      </div>
    </div>
  )
}
